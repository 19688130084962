/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Debugging/dev flags
    var toautoplay = true;
    toautoplay = false;

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $('#contact-popup-btn').on('click', function(event) {
                    event.preventDefault();
                    // console.log('contact popup btn clicked');

                    show_popup_form();
                });


                $('#popupform-closebtn').on('click', function(event) {
                    event.preventDefault();
                    // console.log('close popup btn clicked');

                    hide_popup_form();
                });

                $('.menu-contact-us').on('click', function(event) {
                    event.preventDefault();
                    // console.log('menu contact us link clicked');

                    show_popup_form();
                });

                $('#header-menu-nav').click(function() {
                    event.preventDefault();
                    $('.header-nav').slideToggle();
                    // console.log('menu button clicked');
                });



                // DG - is very brittle - do on big-carousel generic class!
                $('.owl-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: true,
                    items: 1,
                    navText: ["&nbsp;", "&nbsp;"],
                    autoplay: true,
                    autoplayTimeout: 4000,
                    autoplaySpeed: 1000,
                    autoplayHoverPause: true,
                });
                // menu-top
                // is-primary-template
                // var sticky_menu_element = $('.header')[0];
                // if ($("#is-primary-template").length) {
                //     console.log('is primary template');
                //     sticky_menu_element = '.menu-top';
                // }


                // DG - fix this later! this is breaking menu layout... hmm. rethink clean

                    // stick all menu for all. only way to make sense
                // if (!$("#is-primary-template").length) {
                //     var sticky = new Waypoint.Sticky({
                //         element: $(sticky_menu_element)
                //     });
                // }

                var sticky = new Waypoint.Sticky({
                    element: $('.header')[0]
                });


            },

            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        'style_guide': {
            init: function() {
                // JavaScript to be fired on the about us page

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };


    // Helper methods
    function show_popup_form() {
        // If decide to slide anything in then see
        //http://www.learningjquery.com/2009/02/slide-elements-in-different-directions
        // /dreamcatcher project


        $('#popupform-translayer').addClass('show-popupform');
        $('#popupform-wrap').addClass('show-popupform');
    }

    function hide_popup_form() {
        $('#popupform-translayer').removeClass('show-popupform');
        $('#popupform-wrap').removeClass('show-popupform');
    }


    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
